import { FeatureVideoComponentInterface, FEEDCOMPONENTID, HomeFeedResultInterface, VideoComponentInterface } from '@customTypes/index';
export const getBannerVideos = (HomeFeedResult: HomeFeedResultInterface[]) => {
  if (!Array.isArray(HomeFeedResult)) return [];
  for (const item of HomeFeedResult) {
    const itemId = item?.content[0]?.component?.id;
    if (itemId === FEEDCOMPONENTID.ANNOUNCEMENT_BIG || itemId === FEEDCOMPONENTID.ANNOUNCEMENT_SMALL) {
      return item.content;
    }
  }
  return [];
};
export const isBannerVideo = (video: {
  component: VideoComponentInterface;
}) => {
  const videoId = video?.component?.id;
  return videoId === FEEDCOMPONENTID.ANNOUNCEMENT_BIG || videoId === FEEDCOMPONENTID.ANNOUNCEMENT_SMALL;
};
export const getCarousalVideos = (HomeFeedResult: HomeFeedResultInterface[]) => {
  if (!Array.isArray(HomeFeedResult)) return [];
  // In Carousal Data, We only getting 2 videos, 1 is Banner & 2 is Carousal,
  // So, Simply checking is video is notBanner Video, Thus it must be Carousal Video
  for (const item of HomeFeedResult) {
    const itemId = (item?.content[0] as {
      component: VideoComponentInterface;
    });
    if (!isBannerVideo(itemId)) {
      return item.content;
    }
  }
  return [];
};
export const populateFeatureVideoData = (video: FeatureVideoComponentInterface) => {
  const {
    stream_uid,
    avatar,
    avatar_label,
    image_url,
    is_live,
    playback_url,
    title,
    viewers
  } = video;
  return {
    component: {
      id: stream_uid,
      name: 'FEATURE_VIDEO',
      component_uid: stream_uid,
      isFeatureVideo: true,
      featureVideoData: video,
      data: {
        streamer_image: {
          data: avatar
        },
        streamer_name: {
          data: avatar_label
        },
        thumbnail: {
          data: image_url
        },
        is_live: is_live,
        stream_tags: [{
          data: ''
        }],
        playback_url: playback_url,
        stream_uid: {
          data: stream_uid
        },
        stream_title: {
          data: title
        },
        view_count: {
          data: '' + viewers
        }
      }
    }
  };
};