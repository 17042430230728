function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import { VideoComponentInterface } from '@customTypes/index';
import { converCategoryIdIntoUsername } from '@data/allCategories';
import dayjs from 'dayjs';
import NextImage from 'next/legacy/image';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1busjmm",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%;overflow:hidden;border-radius:30px"
} : {
  name: "yj68p0-VideoThumbnail",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%;overflow:hidden;border-radius:30px;label:VideoThumbnail;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "3hbhlx",
  styles: "border-radius:30px"
} : {
  name: "z1804j-VideoThumbnail",
  styles: "border-radius:30px;label:VideoThumbnail;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const VideoThumbnail = ({
  video,
  hideTitle,
  imagePriority = false
}: {
  video: {
    component: VideoComponentInterface;
  };
  hideTitle?: boolean;
  imagePriority?: boolean;
}) => {
  const videoData = video.component.data;
  const thumbnailUrl = videoData?.thumbnail?.data;
  const streamer_name = videoData?.streamer_name?.data;
  const category_name = converCategoryIdIntoUsername(video?.component?.analytics?.category_name);
  const stream_date = dayjs(videoData?.uploaded_time?.data).format('DD-MM-YYYY');
  return _jsxs("div", {
    css: _ref,
    children: [thumbnailUrl && _jsx(NextImage, {
      layout: "fill",
      objectFit: "cover",
      src: thumbnailUrl,
      alt: !category_name ? `${streamer_name} ${stream_date} Loco Live Stream` : `${streamer_name} ${category_name} ${stream_date} Loco Live Stream`,
      loading: imagePriority ? 'eager' : 'lazy',
      quality: "50",
      priority: imagePriority,
      css: _ref2
    }), !hideTitle && _jsx(ShowTitleComponent, {
      video: video
    })]
  });
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1uq4gt4",
  styles: "position:absolute;top:0px;left:0px;height:100%;width:100%;border-radius:30px"
} : {
  name: "16adyiy-ShowTitleComponent",
  styles: "position:absolute;top:0px;left:0px;height:100%;width:100%;border-radius:30px;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "an8i5l",
  styles: "position:absolute;top:0.5rem;z-index:40;width:100%;flex-direction:column;padding-left:1rem;padding-right:1rem"
} : {
  name: "vlv1cs-ShowTitleComponent",
  styles: "position:absolute;top:0.5rem;z-index:40;width:100%;flex-direction:column;padding-left:1rem;padding-right:1rem;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1thc4ss",
  styles: "display:flex;align-items:flex-start;border-radius:10px"
} : {
  name: "7ockhu-ShowTitleComponent",
  styles: "display:flex;align-items:flex-start;border-radius:10px;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "1xcefhj",
  styles: "border-radius:10px;border-width:1px"
} : {
  name: "1hy7m2y-ShowTitleComponent",
  styles: "border-radius:10px;border-width:1px;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1awlr04",
  styles: "margin-left:0.5rem;display:flex;width:calc(100% - 56px);flex-direction:column"
} : {
  name: "n0tuxw-ShowTitleComponent",
  styles: "margin-left:0.5rem;display:flex;width:calc(100% - 56px);flex-direction:column;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1xryuhf",
  styles: "width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:14px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "3offm8-ShowTitleComponent",
  styles: "width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:14px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "ed87fb",
  styles: "width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:left;font-size:12px;:focus{outline:2px solid transparent;outline-offset:2px;}"
} : {
  name: "nvtttp-ShowTitleComponent",
  styles: "width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:left;font-size:12px;:focus{outline:2px solid transparent;outline-offset:2px;};label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const ShowTitleComponent = ({
  video
}: {
  video: {
    component: VideoComponentInterface;
  };
}) => {
  const videoData = video.component.data;
  const userImage = videoData?.streamer_image?.data;
  const name = videoData?.streamer_name?.data;
  const title = videoData?.stream_title?.data;
  return _jsx("div", {
    css: _ref3,
    style: {
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.76) 100%)'
    },
    children: _jsx("div", {
      css: _ref4,
      children: _jsxs("div", {
        css: _ref5,
        children: [userImage && _jsx(NextImage, {
          src: userImage,
          width: 32,
          height: 32,
          alt: `${videoData.streamer_name.data} Streamer on Loco`,
          objectFit: "cover",
          css: _ref6,
          quality: "50"
        }), _jsxs("div", {
          css: _ref7,
          children: [_jsx("div", {
            css: _ref8,
            children: name
          }), _jsx("div", {
            css: _ref9,
            children: title
          })]
        })]
      })
    })
  });
};
export default VideoThumbnail;